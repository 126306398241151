import React from "react";
import About from "./components/About";
import Beer from "./components/Beer";
import Main from "./components/Main";

import { Hoc } from "./Hoc";
// WinnersComponent

function IndexPage() {
  return (
    <>
      <Main />
      <Beer />
      <About />
    </>
  );
}
export default Hoc(IndexPage);
