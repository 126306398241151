import React from 'react';
import { beer_bottles } from './beer_bottles';
import Hochenbrau from './HochenBrau';
import ScrollAnimation from 'react-animate-on-scroll'

export default function Beer() {
    return (
        <section className='section beer'>
            <div className="container">
                <div className="bottle-content">
                    {beer_bottles.map(
                        (beer, id) => (
                            <div className="flex bottle-description mg-bt-2 flex-items-center grid-gap-2" key={id}>
                                {
                                    id % 2 === 0 ?
                                        <>
                                            <ScrollAnimation
                                                animateIn="animated_item"
                                                animateOut="not_animated">
                                                <div className="bottle">
                                                    <img src={beer.bottle} alt="" />
                                                </div>
                                            </ScrollAnimation>
                                            <div className="data">
                                                <div className='grid grid-gap-1'>
                                                    <ScrollAnimation
                                                        animateIn="animated_item"
                                                        animateOut='not_animated'
                                                        offset='300'>
                                                        <Hochenbrau />
                                                    </ScrollAnimation>
                                                    {beer.name}
                                                </div>
                                                <p className="text roboto mg-tp-2">{beer.description}</p>
                                            </div>
                                        </> :
                                        <>
                                            <div className="data">
                                                <div className='grid grid-gap-1'>
                                                    <ScrollAnimation 
                                                    animateIn="animated_item" 
                                                    animateOut='not_animated'>
                                                        <Hochenbrau />
                                                    </ScrollAnimation>
                                                    {beer.name}
                                                </div>
                                                <p className="text roboto mg-tp-2">{beer.description}</p>
                                            </div>
                                            <ScrollAnimation
                                                animateIn="animated_item"
                                                offset='300'>
                                                <div className="bottle">
                                                    <img src={beer.bottle} alt="" />
                                                </div>
                                            </ScrollAnimation>
                                        </>
                                }

                            </div>
                        )
                    )}
                </div>
            </div>
        </section>
    )
}