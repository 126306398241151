import React from 'react';
import Logo from '../assets/images/logo.png';


export default function Footer() {
    return (
        <footer className='section text text-orange roboto'>
            <div className="container">
                <div className="flex grid-gap-3">
                    <div className="logo">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="data">
                        <p className='mg-bt-2'>
                            Brauerei Monchengladbach, Senefelderstrasse 29, 41066 Monchengladbach</p>

                        <p >Export Verkaufsmanager</p>
                        <p>
                            <a href="mailto:HochenbrauBrauerei@gmail.com">
                                HochenbrauBrauerei@gmail.com</a>
                        </p>

                    </div>
                </div>
            </div>
        </footer>
    )
}