import img1 from '../assets/images/beer/1.png'
import img2 from '../assets/images/beer/2.png'
import img3 from '../assets/images/beer/3.png'
import img4 from '../assets/images/beer/4.png'
import React from 'react';

const Name1 = () => {
    return (
        <svg width="106" height="51" viewBox="0 0 106 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.3114 25.1717C44.053 34.483 39.1477 43.4558 39.3169 46.1645C39.3169 46.5031 39.486 46.6724 39.8243 46.6724C40.8392 46.5031 41.5158 45.9952 42.3615 45.4873C41.5158 46.5031 39.9935 47.8575 38.9786 48.0268C38.302 48.0268 37.4563 47.1803 37.2871 46.1645C37.118 41.9321 41.0084 32.9593 45.7444 25.0024C42.3615 25.0024 39.486 25.1717 32.551 26.0182C25.2778 35.1602 18.5119 46.1645 18.5119 49.5504C18.5119 50.2276 18.8502 50.5662 19.5268 50.7355C19.3577 50.9048 19.0194 50.9048 18.6811 50.9048C17.4971 50.9048 16.6513 49.7197 16.6513 48.0268C16.6513 44.1329 20.3725 36.176 27.1384 26.6954C18.6811 28.0497 11.5769 31.2664 11.7461 32.2822C11.7461 32.79 10.2238 31.7743 10.2238 30.0813C10.2238 26.0182 25.9544 24.4945 29.1681 24.1559C33.9042 17.7226 41.5158 7.2262 41.5158 3.84026C41.5158 1.97799 39.8243 1.4701 37.7946 1.4701C23.4172 1.63939 3.62708 22.6322 3.62708 25.1717C3.62708 25.8489 3.96538 26.0182 4.47281 26.0182C5.1494 26.0182 6.33342 25.6796 6.84085 25.341C6.84085 25.8489 5.14939 27.2032 3.45793 27.2032C1.76647 27.2032 0.582451 26.3568 0.582451 24.6638C0.413305 16.5375 23.9246 0.28502 37.4563 0.115723C41.1775 0.115723 44.8987 0.962206 44.8987 4.85604C44.8987 9.93495 38.6403 18.2305 34.4117 23.648C36.4414 23.4787 38.4712 23.4787 40.5009 23.4787C42.3615 23.4787 44.3913 23.4787 46.5902 23.648C50.988 16.7068 56.0624 9.42706 60.291 4.85604C61.9825 3.16307 63.5048 1.80869 64.6888 0.962208C65.5345 0.454317 66.2111 0.115723 66.5494 0.115723C69.2557 0.115723 70.9472 3.33237 70.1015 2.65518C69.2557 2.14729 67.7334 3.16307 66.042 4.51744C61.6442 8.58057 56.0624 16.1989 50.8188 23.9866C56.0624 24.4945 59.6144 25.341 59.6144 25.6796C58.5995 25.8489 55.2166 25.341 50.3114 25.1717Z" fill="#4E738A" />
            <path d="M54.7092 37.1917C54.7092 37.6996 55.0475 38.0382 56.2315 38.0382C59.7836 38.0382 68.5792 31.4356 73.3152 27.3725C73.4844 27.2032 73.8227 27.5418 73.6535 27.7111C70.1015 31.097 60.4602 40.2391 54.2018 40.2391C52.6795 40.2391 50.8188 38.5461 50.8188 37.1917C50.8188 36.0066 51.1571 35.1602 51.4954 33.9751C52.6794 31.2663 55.0475 28.219 58.0921 25.6795C62.1516 22.2936 66.3803 20.9392 68.41 20.9392C69.0866 20.9392 71.2855 22.1243 71.2855 23.3094C71.2855 26.3567 62.1516 33.1286 56.739 33.2979C55.7241 34.483 54.8783 36.0066 54.7092 37.1917ZM57.2464 32.4514C61.475 31.9435 69.4249 24.1559 69.594 22.2936C64.3505 24.1559 58.7687 29.912 57.2464 32.4514Z" fill="#4E738A" />
            <path d="M84.3097 9.93496C85.832 8.0727 87.016 6.54902 88.3692 5.53324C89.0458 5.02535 89.7224 4.51746 90.0607 4.51746C92.767 4.34816 94.6276 7.22621 93.7819 6.54902C92.0904 5.70254 89.0458 10.2736 86.8469 12.813C84.8171 15.5218 82.7874 18.2305 80.4193 21.7858C76.1907 28.0497 72.6386 34.1444 71.4546 37.3611C73.4844 37.5304 81.4342 29.7427 84.3097 27.2033C84.648 27.034 84.8171 27.3726 84.648 27.5419C82.28 30.2506 73.9918 38.8848 71.1163 40.0698C70.4397 40.2391 69.9323 40.0698 69.594 39.9005C69.2557 39.7312 68.0717 38.7155 67.9025 38.0383C67.9025 37.869 67.7334 37.869 67.7334 37.3611C67.7334 35.8374 69.9323 31.2664 75.8524 22.1243C78.7279 17.384 81.7725 13.1516 84.3097 9.93496Z" fill="#4E738A" />
            <path d="M95.8116 9.93496C97.334 8.0727 98.518 6.54902 99.8711 5.53324C100.548 5.02535 101.224 4.51746 101.563 4.51746C104.269 4.34816 106.13 7.22621 105.284 6.54902C103.592 5.70254 100.548 10.2736 98.3488 12.813C96.3191 15.5218 94.2893 18.2305 91.9213 21.7858C87.6926 28.0497 84.1406 34.1444 82.9566 37.3611C84.9863 37.5304 92.9362 29.7427 95.8116 27.2033C96.1499 27.034 96.3191 27.3726 96.1499 27.5419C93.7819 30.2506 85.4937 38.8848 82.6183 40.0698C81.9417 40.2391 81.4342 40.0698 81.096 39.9005C80.7577 39.7312 79.5736 38.7155 79.4045 38.0383C79.4045 37.869 79.2354 37.869 79.2354 37.3611C79.2354 35.8374 81.4343 31.2664 87.3544 22.1243C90.2298 17.384 93.4436 13.1516 95.8116 9.93496Z" fill="#4E738A" />
        </svg>
    )
}
const Name2 = () => {
    return (
        <svg width="92" height="52" viewBox="0 0 92 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4617 36.4073C10.7405 36.4073 8.37243 35.3924 8.37243 33.7009C8.37243 31.1637 15.3074 27.2734 16.4914 27.2734H16.6606C15.1383 28.1191 10.0639 31.3329 10.0639 33.3626C10.0639 33.5318 10.4022 33.8701 11.2479 33.8701C12.7702 33.8701 14.4617 33.7009 16.4914 33.1935C24.6104 20.6767 37.4655 6.13013 41.0176 6.13013C42.7091 6.13013 44.7388 7.82159 44.908 9.85135C44.5697 9.34391 44.0622 9.17476 43.5548 9.17476C40.6793 9.17476 30.3614 20.8458 22.2424 31.6712C36.789 27.1042 56.7482 16.448 56.7482 8.83647C56.7482 4.26952 50.4898 1.73233 42.0325 1.73233C25.1179 1.73233 3.63634 16.1097 3.63634 22.3681C3.63634 24.3979 6.00438 25.5819 7.1884 25.5819C7.1884 25.5819 7.1884 25.5819 6.34267 25.5819C2.95975 25.5819 0.253418 24.567 0.253418 21.015C0.253418 14.4183 17.5063 0.210022 42.3708 0.210022C47.6143 0.210022 59.1162 2.23977 59.1162 10.0205C59.1162 22.7064 32.222 33.1935 19.1978 35.5615C14.6308 41.8199 11.4171 47.4018 11.4171 49.2624C11.4171 50.1081 11.4171 50.9538 12.2628 50.9538C12.0936 51.123 11.7553 51.123 11.4171 51.123C10.0639 51.123 9.38731 49.939 9.38731 48.0783C9.38731 46.0486 11.4171 41.4816 14.4617 36.4073Z" fill="#E1251B" />
            <path d="M47.2763 41.1434C46.4306 40.9742 45.2466 39.621 45.2466 38.7753C45.2466 36.069 52.1816 25.5819 55.2262 23.0447C55.3953 22.8756 55.7336 22.7064 55.9028 22.7064C57.0868 22.7064 58.9474 24.567 58.9474 25.7511C58.9474 25.9202 58.7783 25.7511 58.2708 25.5819C56.2411 25.4128 49.8135 36.4073 49.1369 38.2679C52.0124 37.4221 59.1166 30.8254 61.992 27.95C62.3303 27.7808 62.4995 28.1191 62.4995 28.2883C58.2708 32.8552 50.6593 40.6359 47.7838 40.8051C47.7838 41.1434 47.6146 41.1434 47.2763 41.1434ZM63.3452 16.9555C63.8527 17.4629 64.0218 18.1395 63.8526 18.1395C63.6835 18.1395 63.3452 17.9703 62.8378 18.1395C61.992 18.4778 59.7931 20.5075 59.2857 21.1841C58.6091 22.0298 57.5942 21.015 57.5942 20.1692C57.5942 18.8161 59.2857 15.6023 60.6389 15.264C61.6537 15.264 62.6686 16.2789 63.3452 16.9555Z" fill="#E1251B" />
            <path d="M73.4938 10.8662C75.0161 9.00561 76.2001 7.4833 77.5533 6.46843C78.2299 5.96099 78.9065 5.45355 79.2447 5.45355C81.9511 5.2844 83.8117 8.15988 82.966 7.4833C81.2745 6.63757 78.2299 11.2045 76.031 13.7417C74.0012 16.448 71.9715 19.1544 69.6034 22.7064C65.3748 28.9648 61.8227 35.0541 60.6387 38.2679C62.6684 38.437 70.6183 30.6563 73.4938 28.1191C73.8321 27.95 74.0012 28.2883 73.8321 28.4574C71.464 31.1637 63.1759 39.7902 60.3004 40.9742C59.6238 41.1433 59.1164 40.9742 58.7781 40.8051C58.4398 40.6359 57.2558 39.621 57.0866 38.9445C57.0866 38.7753 56.9175 38.7753 56.9175 38.2679C56.9175 36.7456 59.1164 32.1786 65.0365 23.0447C67.912 18.3086 70.9566 14.08 73.4938 10.8662Z" fill="#E1251B" />
            <path d="M75.1851 30.318C75.016 31.1637 76.2 31.8403 76.7074 32.3478C84.319 27.95 90.5774 26.9351 91.0848 26.9351C91.254 26.9351 91.4231 27.1042 91.4231 27.1042C91.9306 27.2734 82.2892 29.8106 77.2149 32.8552C77.8915 33.3626 79.0755 34.7158 78.9063 35.7307C78.2297 38.7753 71.9713 41.3125 68.7576 41.3125C67.7427 41.3125 67.2353 40.4668 66.897 39.4519C67.0661 37.4221 74.1702 33.3626 75.6926 32.686C75.1851 32.0095 72.3096 30.318 72.8171 28.1191C73.6628 24.3979 80.4286 20.8458 84.6573 20.8458C86.0105 20.8458 86.687 23.0447 86.5179 24.3979C86.5179 25.0745 83.4733 27.95 82.1201 27.95L81.951 27.7808C82.1201 27.7808 85.3339 24.3979 85.3339 23.383C83.135 23.5522 75.6926 28.1191 75.1851 30.318ZM67.9118 39.1136C69.7725 38.6062 75.6926 35.5615 75.6926 33.7009C72.9862 35.3924 69.4342 37.7604 67.9118 39.1136Z" fill="#E1251B" />
        </svg>
    )
}
const Name3 = () => {
    return (
        <svg width="173" height="51" viewBox="0 0 173 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.96721 46.7403C18.4691 46.7403 41.9804 35.2384 41.9804 27.9651C41.9804 24.9205 37.9209 23.229 33.354 21.8758C27.603 20.3535 21.0063 19.5078 21.0063 15.2791C21.0063 7.16014 36.5678 0.732586 43.5027 0.56344C47.9005 0.394294 50.776 1.40917 50.776 5.13038C50.6069 10.8813 42.3187 16.9706 37.2444 16.9706C36.2295 16.9706 34.8763 16.6323 34.8763 15.4483C34.8763 13.4185 36.7369 11.3888 37.7518 10.8813C37.0752 11.7271 36.7369 12.742 36.7369 13.4185C36.7369 13.7568 37.0752 13.7568 37.2444 13.7568C39.2741 13.7568 48.5771 8.34416 49.2537 3.94636C49.4229 2.42405 48.2388 1.91661 46.3782 1.91661C45.8708 1.91661 45.1942 1.91661 44.6868 2.08576C38.5975 2.76234 24.3892 10.7122 24.3892 15.6174C24.3892 18.3238 28.9562 19.6769 33.8614 20.861C39.2741 22.3833 44.8559 24.0747 44.8559 29.4874C44.8559 39.9745 21.0063 50.2924 7.81294 50.2924C4.59917 50.2924 0.0322266 49.4466 0.0322266 45.5563C0.0322266 38.4521 21.6829 26.9502 27.9413 27.1194C15.2554 30.5023 3.07685 41.1585 3.07685 44.7105C2.90771 46.7403 5.4449 46.7403 6.96721 46.7403Z" fill="#E1251B" />
            <path d="M51.1143 38.1139C55.5121 37.6064 63.6311 31.1789 68.1981 27.2885C68.3672 27.1194 68.7055 27.4577 68.5364 27.4577C64.4769 31.348 55.1738 39.9745 49.2537 39.9745C47.7314 39.9745 46.04 38.4521 45.7017 36.9298C45.7017 36.5915 45.7017 36.0841 45.8708 35.4075C46.2091 33.3778 47.9006 29.9948 51.1143 26.9502C55.0047 23.0599 59.9099 20.5227 63.2928 20.5227C64.4769 20.5227 65.9992 22.2141 65.9992 22.8907C65.9992 24.7513 63.6311 26.4428 62.1088 27.6268C61.9397 27.796 61.7705 27.6268 61.9397 27.4577C62.7854 26.6119 64.646 22.8907 64.3077 22.5524C59.2333 22.7216 49.7612 33.5469 49.7612 36.7607C49.592 37.9447 50.6069 38.1139 51.1143 38.1139Z" fill="#E1251B" />
            <path d="M68.3674 32.7012C73.7801 26.9502 82.0682 21.1993 83.7597 21.1993C84.9437 21.1993 86.466 23.7365 85.7894 24.2439C84.6054 25.2588 77.163 35.2384 76.9938 37.6064C80.0385 36.7607 86.8043 30.164 89.8489 27.2885C90.1872 27.1194 90.3564 27.4577 90.3564 27.6268C86.9735 31.348 81.2225 37.2681 77.6704 39.467C77.3321 39.6362 76.4864 39.9745 75.979 40.1436C75.8098 40.1436 75.6407 40.3128 75.3024 40.1436C74.2875 39.8053 73.2726 38.1139 73.2726 37.2681C73.2726 34.5618 78.347 26.7811 80.8842 24.5822C78.0087 25.9354 73.9492 28.8108 70.5663 32.0246C70.0589 32.532 68.1982 34.3927 68.1982 34.3927C66.6759 35.915 65.6611 37.4373 65.1536 38.4522C64.6462 39.467 64.8153 40.1436 65.9994 40.1436C65.4919 40.3128 64.8153 40.1436 64.477 39.9745C64.1387 39.8053 63.1239 38.7904 62.7856 38.1139C62.6164 37.7756 62.7856 36.2533 64.1387 33.0395C66.6759 27.2885 73.2726 17.8163 77.6704 11.8962C79.0236 10.2048 81.3916 7.16015 83.4214 5.46869C84.2671 4.96125 84.9437 4.62296 85.282 4.62296C87.9883 4.62296 89.8489 7.49843 89.0032 6.991C87.9883 6.48356 86.6352 7.49843 84.7746 9.52819C79.8693 14.7717 72.2578 24.9205 68.3674 32.7012Z" fill="#E1251B" />
            <path d="M101.182 23.229C101.351 23.0599 101.689 23.0599 101.858 23.0599C103.042 23.0599 105.072 24.9205 105.241 26.1045C105.241 26.2736 105.072 26.1045 104.565 25.9353C103.888 25.9353 102.873 27.1194 101.012 29.9948C99.321 32.7012 97.9678 36.4224 98.137 37.099C104.226 33.3778 111.838 24.5822 116.236 17.478C116.574 17.1397 116.574 17.1397 116.405 17.6472C112.853 26.9502 98.9827 40.3127 97.1221 40.3127C95.9381 40.3127 94.7541 38.4521 94.7541 38.1138C94.5849 36.2532 95.7689 32.8703 97.1221 30.3331C94.9232 32.8703 91.5403 36.5915 89.6797 38.1138C88.1574 39.2979 86.8042 40.4819 86.2968 40.4819C85.451 40.4819 84.267 38.6213 84.0979 38.283C83.9287 35.7458 86.4659 30.3331 89.1722 26.2736C90.6945 24.0747 91.5403 22.8907 92.7243 22.045C92.8934 21.8758 93.2317 21.7067 93.4009 21.7067C94.5849 21.7067 96.6147 23.5673 96.6147 24.7513C96.6147 24.9205 96.4455 24.7513 96.1072 24.5822C95.5998 24.5822 95.0923 25.0896 94.4158 25.7662C92.5551 27.9651 88.3265 34.3926 87.9882 37.099C89.5105 36.2532 93.4009 33.0395 97.7987 27.9651C99.321 25.4279 100.336 23.9056 101.182 23.229Z" fill="#E1251B" />
            <path d="M121.986 30.6714C120.464 32.1937 111.161 40.1436 109.131 40.1436C108.962 40.1436 108.793 39.9745 108.624 39.9745C107.947 39.467 106.763 37.7756 106.763 36.7607C106.763 31.5172 117.758 20.861 126.553 20.861C128.414 20.861 130.782 21.7067 130.782 23.9056C130.782 24.7513 130.274 25.597 129.936 26.1045C129.767 26.1045 129.767 26.1045 129.767 26.1045C129.936 25.597 130.274 25.0896 130.274 24.413C130.274 22.8907 129.429 22.2141 127.906 22.3833C125.2 22.5524 121.648 24.5822 118.434 27.1194C113.867 31.0097 110.315 37.099 110.654 37.2681C110.823 37.2681 110.823 37.2681 110.992 37.2681C112.514 36.7607 119.28 31.8554 121.817 29.8257C122.663 28.98 125.877 26.1045 127.061 25.4279C128.076 24.9205 129.598 27.6268 129.429 28.1342C129.26 27.6268 124.862 32.8703 123.509 35.915C123.17 36.5915 123.001 37.099 123.001 37.6064C126.046 36.7607 132.812 30.164 135.856 27.4577C136.025 27.2885 136.364 27.6268 136.195 27.7959C131.966 32.3629 124.185 40.3127 121.31 40.3127C121.141 40.3127 120.802 40.1436 120.464 39.9745C119.787 39.6362 118.942 38.6213 118.942 37.7756C118.942 37.2681 119.111 36.7607 119.28 36.0841C119.787 34.0543 121.141 31.8554 121.986 30.6714Z" fill="#E1251B" />
            <path d="M139.578 21.1992C139.916 21.0301 140.085 21.0301 140.254 21.0301C141.438 21.0301 143.299 22.8907 143.299 24.0747C143.299 24.2439 143.13 24.0747 142.791 23.9056C142.115 23.9056 140.423 25.7662 139.07 27.6268C137.717 29.3183 136.026 31.6863 135.011 33.3778C137.548 29.9948 142.284 26.1045 146.513 23.0598C148.373 21.7067 149.896 20.861 150.741 20.861C151.249 20.861 153.278 22.8907 152.94 23.229C151.925 24.413 147.866 27.7959 146.343 31.5171C148.204 31.348 150.741 29.9948 153.278 27.9651C154.462 26.7811 155.985 25.2587 156.831 24.2439C156.661 23.9056 156.661 23.5673 156.661 23.0598C156.661 20.1844 158.522 18.4929 158.86 18.4929C159.537 18.4929 160.89 19.5078 160.89 20.5227C160.89 21.0301 158.184 23.9056 157.845 24.413C158.015 24.5822 158.691 24.7513 159.706 24.7513C162.92 24.7513 168.84 23.229 170.024 21.3684C170.362 20.8609 172.899 24.0747 172.392 25.0896C168.671 25.597 155.985 33.0395 152.94 36.4224C153.955 35.9149 155.816 35.4075 157.845 35.4075C161.397 35.4075 165.457 36.5915 166.979 39.6362C167.148 39.9744 166.979 40.1436 166.81 39.9744C165.626 37.9447 163.258 36.9298 158.691 36.9298C153.786 36.9298 150.234 40.3127 149.388 40.3127C148.712 40.3127 147.358 38.6213 147.528 37.7756C148.542 34.9001 158.353 27.6268 163.766 25.4279C162.751 25.597 161.567 25.7662 160.552 25.7662C159.199 25.7662 158.184 25.597 157.507 24.9205C155.308 27.6268 153.278 29.1491 150.91 30.8406C149.219 32.0246 147.189 33.2086 145.667 33.2086C144.821 33.2086 143.299 31.5171 143.299 31.0097C143.299 29.6565 144.821 26.9502 146.005 25.4279C141.607 27.9651 139.409 29.9948 136.026 33.5469C133.658 36.2532 130.782 40.4819 131.797 40.8202C130.444 41.1585 129.26 39.6362 128.921 38.283C128.752 37.7755 129.091 36.9298 129.598 35.9149C130.782 33.2086 132.981 29.8257 134.842 27.1194C136.195 24.5822 138.394 22.2141 139.578 21.1992Z" fill="#E1251B" />
        </svg>
    )
}
const Name4 = () => {
    return (
        <svg width="141" height="51" viewBox="0 0 141 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.9486 34.3629C64.4059 24.3833 73.2015 10.8516 73.2015 4.08575C73.2015 3.40917 73.0324 2.73258 72.8633 2.22514C72.6941 1.7177 72.1867 1.54856 71.5101 1.54856C70.3261 1.54856 68.8037 2.056 68.4655 2.39429C69.3112 1.71771 71.6792 0.0262451 73.3707 0.0262451C75.0621 0.0262451 76.2462 0.871975 76.5845 1.7177C76.7536 2.39429 76.9228 3.24002 76.9228 3.9166C76.9228 11.6973 66.2666 26.0747 56.6252 36.2235C49.8594 43.4968 43.7701 48.2328 41.5712 48.5711C38.6958 49.0786 37.85 47.0488 37.85 45.3574C38.0192 37.4075 46.4765 22.861 50.1977 17.7866C44.2776 21.6769 19.4131 44.8499 19.4131 49.2477C19.4131 49.4169 19.7514 49.4169 19.9205 49.4169C20.0897 49.4169 20.428 49.4169 20.5971 49.2477C20.5971 49.2477 20.0897 50.0935 19.244 50.0935C18.3982 50.0935 17.2142 49.0786 17.2142 48.402C17.2142 42.3127 38.1883 11.8665 38.1883 6.1155C38.1883 3.9166 35.9894 3.40916 33.9597 3.40916C21.9503 3.40916 3.68253 23.3684 3.68253 26.413C3.68253 27.0896 3.85167 27.4279 5.03569 27.4279C6.05057 27.4279 6.55801 27.0896 6.55801 27.2587C6.55801 27.597 4.52826 28.7811 3.34423 28.7811C1.14534 28.7811 0.46875 26.9205 0.46875 25.7364C0.46875 18.1249 21.9503 2.22514 33.2831 2.22514C37.3426 2.22514 40.8946 4.08575 40.8946 8.14525C40.8946 16.7717 29.0544 33.5171 24.9949 40.283C33.2831 29.7959 52.7349 11.8665 54.9338 11.8665C56.2869 11.8665 59.1624 13.8962 59.3316 15.0802C58.655 14.4037 57.9784 14.4037 57.8092 14.4037C55.1029 14.4037 37.5117 47.5563 41.4021 47.0488C43.9393 46.7105 49.6902 41.8053 55.9486 34.3629Z" fill="#E1251B" />
            <path d="M66.6047 39.099C66.6047 39.6064 66.943 39.9447 68.127 39.9447C71.6791 39.9447 80.4747 33.348 85.2108 29.2885C85.3799 29.1194 85.7182 29.4577 85.5491 29.6268C81.997 33.0097 72.3557 42.1436 66.0973 42.1436C64.575 42.1436 62.7144 40.4521 62.7144 39.099C62.7144 37.915 63.0526 37.0692 63.3909 35.8852C64.575 33.1789 66.943 30.1342 69.9876 27.597C74.0471 24.2141 78.2758 22.861 80.3055 22.861C80.9821 22.861 83.181 24.045 83.181 25.229C83.181 28.2736 74.0471 35.0395 68.6345 35.2086C67.6196 36.3926 66.7739 37.915 66.6047 39.099ZM69.1419 34.3629C73.3706 33.8555 81.3204 26.0747 81.4895 24.2141C76.246 26.0747 70.6642 31.8257 69.1419 34.3629Z" fill="#E1251B" />
            <path d="M80.813 42.1436C79.9672 41.9744 78.7832 40.6213 78.7832 39.7755C78.7832 37.0692 85.7182 26.5821 88.7628 24.0449C88.932 23.8758 89.2703 23.7066 89.4394 23.7066C90.6234 23.7066 92.484 25.5673 92.484 26.7513C92.484 26.9204 92.3149 26.7513 91.8075 26.5821C89.7777 26.413 83.3502 37.4075 82.6736 39.2681C85.549 38.4224 92.6532 31.8257 95.5287 28.9502C95.867 28.781 96.0361 29.1193 96.0361 29.2885C91.8075 33.8554 84.1959 41.6361 81.3204 41.8053C81.3204 42.1436 81.1513 42.1436 80.813 42.1436ZM96.8818 17.9557C97.3893 18.4631 97.5584 19.1397 97.3893 19.1397C97.2201 19.1397 96.8818 18.9706 96.3744 19.1397C95.5287 19.478 93.3298 21.5077 92.8223 22.1843C92.1457 23.0301 91.1309 22.0152 91.1309 21.1695C91.1309 19.8163 92.8223 16.6025 94.1755 16.2642C95.1904 16.2642 96.2053 17.2791 96.8818 17.9557Z" fill="#E1251B" />
            <path d="M97.0509 31.3182C96.8817 32.164 98.0657 32.8406 98.5732 33.348C106.185 28.9502 112.443 27.9353 112.951 27.9353C113.12 27.9353 113.289 28.1045 113.289 28.1045C113.796 28.2736 104.155 30.8108 99.0806 33.8554C99.7572 34.3629 100.941 35.716 100.772 36.7309C100.095 39.7755 93.8371 42.3127 90.6233 42.3127C89.6084 42.3127 89.101 41.467 88.7627 40.4521C88.9318 38.4224 96.036 34.3629 97.5583 33.6863C97.0508 33.0097 94.1754 31.3182 94.6828 29.1193C95.5285 25.3981 102.294 21.8461 106.523 21.8461C107.876 21.8461 108.553 24.045 108.384 25.3981C108.384 26.0747 105.339 28.9502 103.986 28.9502L103.817 28.7811C103.986 28.7811 107.2 25.3981 107.2 24.3833C105.17 24.5524 97.7274 29.1193 97.0509 31.3182ZM89.9467 40.1138C91.8073 39.6064 97.7274 36.5618 97.7274 34.7012C94.852 36.3926 91.2999 38.7607 89.9467 40.1138Z" fill="#E1251B" />
            <path d="M110.752 31.3182C110.583 32.164 111.767 32.8406 112.274 33.348C119.886 28.9502 126.144 27.9353 126.652 27.9353C126.821 27.9353 126.99 28.1045 126.99 28.1045C127.497 28.2736 117.856 30.8108 112.782 33.8554C113.458 34.3629 114.642 35.716 114.473 36.7309C113.797 39.7755 107.538 42.3127 104.324 42.3127C103.31 42.3127 102.802 41.467 102.464 40.4521C102.633 38.4224 109.737 34.3629 111.259 33.6863C110.752 33.0097 107.877 31.3182 108.384 29.1193C109.23 25.3981 115.996 21.8461 120.224 21.8461C121.577 21.8461 122.254 24.045 122.085 25.3981C122.085 26.0747 119.04 28.9502 117.687 28.9502L117.518 28.7811C117.687 28.7811 120.901 25.3981 120.901 24.3833C118.702 24.5524 111.259 29.1193 110.752 31.3182ZM103.479 40.1138C105.339 39.6064 111.259 36.5618 111.259 34.7012C108.553 36.3926 105.001 38.7607 103.479 40.1138Z" fill="#E1251B" />
            <path d="M121.07 39.099C121.07 39.6064 121.408 39.9447 122.592 39.9447C126.144 39.9447 134.94 33.348 139.676 29.2885C139.845 29.1194 140.184 29.4577 140.014 29.6268C136.462 33.0097 126.821 42.1436 120.563 42.1436C119.04 42.1436 117.18 40.4521 117.18 39.099C117.18 37.915 117.518 37.0692 117.856 35.8852C119.04 33.1789 121.408 30.1342 124.453 27.597C128.512 24.2141 132.741 22.861 134.771 22.861C135.447 22.861 137.646 24.045 137.646 25.229C137.646 28.2736 128.512 35.0395 123.1 35.2086C122.085 36.3926 121.239 37.915 121.07 39.099ZM123.607 34.3629C127.836 33.8555 135.786 26.0747 135.955 24.2141C130.711 26.0747 125.13 31.8257 123.607 34.3629Z" fill="#E1251B" />
        </svg>
    )
}

export const beer_bottles = [
    {
        name: <Name1 />,
        description: `Gefiltertes hellgoldenes Bier mit einem angenehmen Gerstenaroma und einem erfrischenden Geschmack. Seine Bestandteile sind reines Trinkwasser, Gerstenmalz, Hopfenextrakt`,
        bottle: img1
    },
    {
        name: <Name2 />,
        description: `Gefiltertes helles Bier hat ein angenehmes, mildes Aroma und einen harmonischen Geschmack mit Gerstennoten und einer angenehmen Bitterkeit. Bestandteile des Getränkes sind reines Trinkwasser, Gerstenmalz, Hopfenextrakt`,
        bottle: img2
    },
    {
        name: <Name3 />,
        description: `Dunkles gefiltertes Bier mit einem harmonischen Aroma und einem Gerstengeschmack. Bestandteile des Getränkes sind reines Trinkwasser, Gerstenmalz, Hopfenextrakt`,
        bottle: img3
    },
    {
        name: <Name4 />,
        description: `Helles nicht gefiltertes Bier mit einem intensiven Geschmack und einem milden, leicht bitteren Aroma. Bestandteile des Getränkes sind reines Trinkwasser, Weizen- und Gerstenmalz, Hopfenextrakt und Hefe`,
        bottle: img4
    }
]