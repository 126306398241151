import React from 'react';
import Logo from './Logo';



export default function Main() {
    return (
        <section className='main section text text-white'>
            <div className="container">
                <h1>Hochenbrau</h1>
                <div className="logo">
                    <Logo />
                </div>
                <div className="flex flex-items-center mg-tp-7">
                    <div className='pirata text-header'>Bewahrung der deutschen Brautraditionen</div>
                    <div className='roboto'>Das Hochenbrau Bier ist ein Beispiel für die höchste Qualität des echten deutschen Bieres. Jede sich selbst achtende Brauerei in Deutschland strebt an, die Herzen ihrer Kunden zu erobern. An der Hochenbrau-Herstellung sind nur erfahrene Meisterbrauer beteiligt. Mit Sorgfalt überwachen sie technologische Verfahren und die obligatorische Pflege der Brautraditionen. Sie suchen dabei ständig nach Verbesserungen der bereits vorhandenen Biersorten. Das alles wird ausschließlich dafür getan, damit Sie einen fabelhaften und erfrischenden Hochenbrau-Geschmack genießen können</div>
                </div>
            </div>
        </section>
    )
}