import React from 'react'
import img1 from '../assets/images/barrel.png'
import img2 from '../assets/images/barrel2.png'
import img3 from '../assets/images/barrel3.png'
import ScrollAnimation from 'react-animate-on-scroll'
import Footer from './Footer'


export default function About() {
    return (
        <section className="section about">
            <div className="container">
                <div className="grid grid-col-3 grid-gap-2 text roboto">
                    <ScrollAnimation animateIn="appear" animateOut="disappear">
                        <div className="about-item">
                            <div className="rounded-icon">
                                <img src={img1} alt="" />
                            </div>
                            <div className="content">
                                <p>Das Hochenbrau Bier wird in der Brauerei OeTTINGER (OeTTINGER Brauerei GmbH) in Mönchengladbach (Mönchengladbach Brewery), Deutschland gebraut. Die Traditionen des Unternehmens beginnen ihren Lauf im mittelalterlichen Europa</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="appear" animateOut="disappear">
                        <div className="about-item">
                            <div className="rounded-icon">
                                <img src={img2} alt="" />
                            </div>
                            <div className="content">
                                <p>Erwähnung der Brauerei, welche von der adligen Familie von Oettinger gegründet wurde, ist mit 1333 datiert</p>
                            </div>
                        </div>

                    </ScrollAnimation>
                    <ScrollAnimation animateIn="appear" animateOut="disappear">
                        <div className="about-item">
                            <div className="rounded-icon">
                                <img src={img3} alt="" />
                            </div>
                            <div className="content">
                                <p>Heutzutage werden die Getränke der Firma in ultrahochmodernen Anlagen in Übereinstimmung mit dem "Deutschen Reinheitsgebot von 1516" gebraut. Dieses Gesetz reglementiert die Bierherstellung seit bereits fünfhundert Jahren</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
            <Footer />
        </section>
    )
}